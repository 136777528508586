<script>


/**
 * Page-error component
 */
export default {
  data() {
    return {}
  },
  components: {

  },
  methods: {
    observeDOM() {
      // eslint-disable-next-line no-unused-vars
      const observer = new MutationObserver((mutationsList, observer) => {
        for (const mutation of mutationsList) {
          if (mutation.addedNodes) {
            for (const node of mutation.addedNodes) {
              if (node.tagName === "JDIV") {
                node.style.display = "none";
              }
            }
          }
        }
      });
      const config = {childList: true, subtree: true};
      const targetNode = document.documentElement; // 或者指定包含 JivoSite 的父元素
      observer.observe(targetNode, config);
    },

  },
  mounted() {
    this.observeDOM();
    // 跳转到新页面
    const newPageUrl = "/";
    const pageTitle = "sohash.com";
    const newUrl = window.location.protocol + "//" + window.location.host + newPageUrl;
    window.history.pushState({path: newUrl}, pageTitle, newUrl);
  },

}
</script>

<template>
  <div>
    <div class="back-to-home rounded d-none d-sm-block">
      <router-link to="/" class="btn btn-icon btn-soft-primary">
<!--        <home-icon class="icons"></home-icon>-->
      </router-link>
    </div>

    <!-- ERROR PAGE -->
    <section class="bg-home d-flex align-items-center" style="min-height: 100vh;">
      <div class="container">
        <div class="row justify-content-center align-items-center" style="min-height: 100%;">
          <div class="col-lg-8 col-md-12 text-center">
            <img src="@/images/tmp/error-404.png" class="img-fluid mx-auto" alt="error404" style="max-width: 30%; height: auto;">
            <div class="text-uppercase mt-4 display-6" style="font-size: 30px;">Page Not Found</div>
          </div>
        </div>

        <div class="row mt-4 justify-content-center">
          <div class="col-md-12 text-center">
            <router-link to="/" class="btn btn-primary">Go To Home</router-link>
          </div>
        </div>
      </div>
    </section>
    <!--end section-->

  </div>
</template>

<style>
@media (max-width: 992px) {
  #footer-bar {
    display: none !important;
  }
}
</style>